import { User } from '@/models/user.model'
import { useSession } from 'next-auth/react'

export const useGetUserInfoClientSide = ():
  | {
      userInfo: User
      status: 'authenticated',
      env: string
    }
  | {
      userInfo: null
      status: 'unauthenticated' | 'loading',
      env: string
    } => {
  const { data: sessionData, status } = useSession()

  if (status === 'authenticated') {
    return {
      userInfo: sessionData.decodedAccessToken,
      status,
      env: process.env.ENV
    }
  }

  return { userInfo: null, status, env: process.env.ENV }
}
