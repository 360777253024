import * as clickHandlers from '@/modules/tealium/utilities/events/clickHandlers'

export const CREATE_PATH = 'support/create'
export const ONBOARDING_PATH = '/support/create?apiOnboarding'

const RADIO_BTN_SELECTOR = '#fieldset_select_request .usb--radio-wrapper',
  SELECTED_RADIO_BTN_SELECTOR = 'input[type="radio"]:checked'

let handlersRemoved: boolean = false

export function attachClickHandlers() {
  try {
    const radioButtons = document.querySelectorAll(RADIO_BTN_SELECTOR) || []

    radioButtons.forEach((button) => {
      button.addEventListener('click', clickHandlers.radioBtnClickHandler)
    })
  } catch (error) {
    console.log(error)
  }
}

export function removeClickHandlers() {
  try {
    const radioButtons = document.querySelectorAll(RADIO_BTN_SELECTOR) || []

    radioButtons.forEach((button) => {
      button.removeEventListener('click', clickHandlers.radioBtnClickHandler)
    })

    handlersRemoved = true
  } catch (error) {
    console.log(error)
  }
}

export function clickAutoSelectedRadioBtns(path: string) {
  let selectedElement: any =
      document?.querySelector(SELECTED_RADIO_BTN_SELECTOR) || {},
    onboardingSelected = path === ONBOARDING_PATH && handlersRemoved === false

  if (onboardingSelected || selectedElement.value === 'help') {
    handlersRemoved = true
    clickHandlers.radioBtnClickHandler()
  }
}
